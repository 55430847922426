<template>
  <l-table-container>
    <template v-slot:search>
      <a-form ref="formRef" :model="search.data" :labelCol="{ style: { width: '80px' } }">
        <l-search-container @search="onSearch" @reset="onReset">
          <a-col v-bind="searchCol">
            <a-form-item label="统计日期">
              <a-row type="flex" :gutter="10" :wrap="false">
                <a-col :flex="1">
                  <a-date-picker v-model:value="search.data.startDate" inputReadOnly placeholder="开始日期" value-format="YYYY-MM-DD" style="display: block" />
                </a-col>

                <a-col flex="20px">
                  <div style="margin-top: 5px">~</div>
                </a-col>

                <a-col :flex="1">
                  <a-date-picker v-model:value="search.data.endDate" inputReadOnly placeholder="结束日期" value-format="YYYY-MM-DD" style="display: block" />
                </a-col>
              </a-row>
            </a-form-item>
          </a-col>

          <a-col v-bind="searchCol">
            <a-form-item label="俱乐部ID" name="clubId">
              <a-input v-model:value.number="search.data.clubId" placeholder="请输入俱乐部ID" allowClear> </a-input>
            </a-form-item>
          </a-col>
        </l-search-container>
      </a-form>
    </template>

    <l-table @reload="onSearch" :columns="columns" :data-source="data" :pagination="pagination" @change="handleTableChange" row-key="clubId" :loading="loading">
      <!-- <template #icon="{ record }">
        <a-avatar :size="30" :src="record.icon"> </a-avatar>
      </template> -->
    </l-table>
  </l-table-container>
</template>

<script lang="ts">
import http from "@/common/http";
import { defineComponent, onMounted, reactive, ref } from "vue";
import { pagConfig, searchCol } from "@/config/app";
import { PaginationProps } from "ant-design-vue";

export default defineComponent({
  name: "gm_day_club_user_group_statistics",
  components: {
    // Edit,
  },
  setup() {
    //搜索绑定数据
    const search = reactive({
      //是否显示有的搜索框
      showAll: false,

      //搜索数据
      data: {
        startDate: undefined,
        endDate: undefined,
        clubId: "",
      },

      //点击搜索后的搜索数据
      relData: {},
    });

    const columns = ref([
      // {
      //   title: "ID",
      //   width: 80,
      //   dataIndex: "id",
      //   fixed: "left",
      // },

      {
        title: "俱乐部ID",
        width: 100,
        dataIndex: "clubId",
        ellipsis: true,
      },

      {
        title: "俱乐部名称",
        width: 100,
        dataIndex: "clubName",
        ellipsis: true,
      },

      {
        title: "耗钻",
        width: 100,
        dataIndex: "totalBearnsConsume",
        ellipsis: true,
      },

      {
        title: "活跃人数",
        width: 110,
        dataIndex: "activePlayerCount",
        ellipsis: true,
      },

      {
        title: "总场次",
        width: 160,
        dataIndex: "totalGroupCount",
        ellipsis: true,
      },

      {
        title: "麻将场次",
        width: 160,
        dataIndex: "hnmjGroupCount",
        ellipsis: true,
      },
    ]);

    //表格加载中
    const loading = ref(true);

    const pagination = reactive(pagConfig);

    const data = ref([]);

    const formRef = ref();

    //获取列表
    const getList = async () => {
      loading.value = true;
      const result = await http.get("gm_day_club_user_group_statistics", {
        page: pagination.current,
        page_size: pagination.pageSize,
        ...search.relData,
      });

      data.value = result.data.data;
      pagination.total = result.data.count;

      loading.value = false;
    };

    //监听页码切换
    const handleTableChange = (pag: PaginationProps) => {
      pagination.current = pag?.current;
      pagination.pageSize = pag?.pageSize;
      getList();
    };

    //点击搜索按钮
    const onSearch = () => {
      pagination.current = 1;
      search.relData = search.data;
      getList();
    };

    //点击重置按钮
    const onReset = () => {
      formRef.value.resetFields();
      search.data.startDate = undefined;
      search.data.endDate = undefined;
      onSearch();
    };

    onMounted(() => {
      getList();
    });

    return {
      data,
      columns,
      search,
      pagination,
      formRef,
      loading,
      handleTableChange,
      onSearch,
      onReset,
      searchCol,
    };
  },
});
</script>
